* {
    box-sizing: border-box;
}

html,
body,
section,
div {
    height: 100%;
}

body {
    color: #fff;
    font-size: 1.25rem;
    line-height: 150%;
    text-align: center;
    background-image: linear-gradient(to right, #5CAAFF, #A55FFF);
}

section {
    width: 100%;
}

article {
    position: relative;
    top: 50%;
    left: 50%;
    padding: 1rem;
    text-align: center;
    transform: translate(-50%, -50%);
}

/* Pattern styles */
.left-half {
    float: left;
    width: 50%;
}

.right-half {
    float: left;
    width: 50%;
}

img {
    width: 80%;
}

.formbtn {
    background-color: #0a0a0a;
    color: white;
    padding: 20px;
    border-radius: 5px;
    left: 10px;
    border: none;
    margin: 8px;
    cursor: pointer;
}

a {
    color: #ffff;
    font-size: 1rem;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-weight: 300;
    font-size: 1.5rem;
    margin: 0 0 1rem;
}

@media screen and (max-width: 600px) {
    .right-half {
        display: none;
    }

    .left-half {
        width: 100%;
    }
}

.googlebtn {
    margin-left: 30px;
}

.back {
    background-color: #0a0a0a;
    color: white;
    padding: 11px;
    border-radius: 8px;
    left: 10px;
    border: none;
    margin: 8px;
    cursor: pointer;
}